import { getCurrentUser, Roles, URLS } from '@netcurio/frontend-common'
import { HomeWidget, HomeWidgetProps } from '@netcurio/frontend-components'
import React, { ReactElement } from 'react'
import { ConditionalRender } from '../../components/ConditionalRender/ConditionalRender'
import { useCompanySettings } from '../../hooks/useCompanySettings'
import { getUserRolesForCompany } from '../../utilities/getUserRolesForCompany'
import styles from './home.module.scss'

export const HomePage = (): ReactElement => {
	const currentUser = getCurrentUser()
	const { companySettings } = useCompanySettings({ rfc: currentUser?.company?.rfc ?? null })
	const userRoles: Roles[] = getUserRolesForCompany()
	const HomeWidgetArray: HomeWidgetProps[] = [
		{
			title: 'newPO',
			link: URLS.PO_NEW,
			acceptedRoles: [Roles.CUSTOMER],
			restrictions: [
				{
					companySettingName: 'process_invoice_in_external_system',
					on: false
				}
			]
		},
		{
			title: 'newInvoice',
			link: URLS.NEW_INVOICE,
			acceptedRoles: [Roles.CUSTOMER, Roles.SUPPLIER],
			restrictions: [
				{
					companySettingName: 'standalone_invoice_workflow_enabled',
					on: true
				}
			]
		},
		{
			title: 'newEntryGR',
			link: URLS.GR_NEW,
			acceptedRoles: [Roles.CUSTOMER],
			restrictions: [
				{
					companySettingName: 'process_invoice_in_external_system',
					on: false
				}
			]
		},
		{
			title: 'newCMText',
			link: URLS.NEW_CREDIT_MEMO,
			acceptedRoles: [Roles.SUPPLIER]
		},
		{
			title: 'reportsText',
			link: URLS.REPORT_LIST,
			acceptedRoles: [Roles.CUSTOMER, Roles.SUPPLIER]
		}
	]
	const validateRender = (widget: HomeWidgetProps) => {
		return widget.acceptedRoles.some((acceptedRole) => {
			if (widget?.restrictions) {
				if (widget.restrictions.length > 0) {
					if (acceptedRole === Roles.CUSTOMER && userRoles.includes(acceptedRole)) {
						return widget.restrictions.some((restriction) => {
							const settingValue: boolean = companySettings
								? (companySettings as unknown as Record<string, boolean>)[
										restriction.companySettingName
									]
								: false
							return settingValue === restriction.on
						})
					}
				}
			}
			return userRoles.includes(acceptedRole)
		})
	}

	return (
		<div className={styles.cardsContainer}>
			{HomeWidgetArray.map((widgetInformation, i) => (
				<ConditionalRender
					key={widgetInformation.title + i}
					condition={validateRender(widgetInformation)}
				>
					<HomeWidget widgetInformationArray={widgetInformation} />
				</ConditionalRender>
			))}
		</div>
	)
}
